import { generateConst } from '@/utils/constants'

export const userConstants = {
  userClasses: {
    CORPORATION: 1, // 法人
    INDIVIDUAL: 2 // 個人
  },
  parkingProviderUserTypes: {
    MANAGEMENT: 'management', // 管理会社
    OWNER: 'owner' // オーナー
  },
  userSearchTypes: {
    PROVIDER: 'provider', // 管理会社、オーナー検索
    CUSTOMER: 'customer' // 問い合わせユーザー検索
  },
  sansanStatuses: {
    ACTIVE: 1, // 有り
    INACTIVE: 0 // 無し
  },
  userStatuses: {
    INACTIVE: 0, // 無効
    ACTIVE: 1, // 有効
    DUPLICATED: 2 // 名寄せ
  },
  updatableComplianceCheckedMails: [
    'takumu.fujioka@azoom.jp',
    'takako.tsuji@azoom.jp',
    'k.fukayama@azoom.jp',
    'n.t.t.van@azoom.jp' // Test STG
  ]
}

const userTypeRegistry = {
  PROJECT_MANAGEMENT: { value: 'project_management', label: '管理会社・PM' },
  OWNER: { value: 'owner', label: 'オーナー' },
  ASSISTANT_MANAGEMENT: { value: 'assistant_management', label: 'AM' },
  OTHER_COMPANY_SUBLEASE: {
    value: 'other_company_sublease',
    label: '他社サブリース'
  },
  OTHER_COMPANY_SASS: { value: 'other_company_saas', label: '他社SaaS' },
  MAINTENANCE: { value: 'maintenance', label: 'メンテナンス' },
  UNKNOWN: { value: '-1', label: '未設定' }
}

export const UserType = generateConst(userTypeRegistry)
